<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("Aankoop dagboek") }} </v-toolbar-title>
			<v-toolbar-items class="kmp">
				<v-autocomplete style="width: auto; max-width: 225px" class="text-overline mr-4 my-auto"
					v-model="selectedSupplier" :items="suppliers" item-text="name" item-value="supplierId"
					placeholder="filter op leverancier" hide-details solo flat dense outlined clearable
					append-icon="mdi-filter-outline" @change="refreshPurchaseJournal" />
				<v-autocomplete style="width: auto; max-width: 225px" class="text-overline my-auto" v-model="fiscalPeriod"
					:items="sessionStore.fiscalPeriods" placeholder="filter op periode" hide-details solo flat dense
					outlined clearable append-icon="mdi-filter-outline" @change="refreshPurchaseJournal" />
			</v-toolbar-items>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-data-iterator :items="purchaseJournal" disable-pagination hide-default-footer>
				<template v-slot:default="{ items }">
					<v-row>
						<v-col cols="12" v-for="invoice in items" :key="invoice.stuknr">
							<purchase-journal-card :invoice="invoice" />
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
			<v-row>
				<v-spacer />
				<v-btn text outlined class="my-3" v-if="this.rowIdent" color="primary" :loading="loading"
					@click="getPurchaseJournal()">
					toon meer ...
				</v-btn>
				<v-spacer />
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import PurchaseJournalCard from "../components/PurchaseJournalCard.vue";
import { useSessionStore } from "@/store/session";
export default {
	components: { PurchaseJournalCard },
	name: "PurchaseJournal",
	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 5,
			};
		},
	},
	data() {
		return {
			fiscalPeriod: 0,
			loading: false,
			purchaseJournal: [],
			rowNum: 0,
			rowIdent: "",
			rowsToBatch: 25,
			suppliers: [],
			selectedSupplier: 0,
			sessionStore: useSessionStore()
		};
	},
	methods: {

		async getPurchaseJournal() {

			try {
				this.loading = true;
				let params = {
					year: Number(this.sessionStore.fiscalYear),
					period: Number(this.fiscalPeriod) || 0,
					supplierId: Number(this.selectedSupplier) || 0,
					rowNum: this.rowNum,
					rowIdent: this.rowIdent,
					rowsToBatch: this.rowsToBatch,
				};
				const response = await caas.rpc("getPurchaseJournal", params);
				if (response.data) {
					this.purchaseJournal.push(
						...response.data.data.purchaseJournal.invoice
					);

					this.rowNum = response.data.rowNum;
					this.rowIdent = response.data.rowIdent;
				} else if (response.data.error) {
					console.log(response.data.error);
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.loading = false;
			}

		},

		async getSuppliersOfFiscalYear() {
			try {
				this.loading = true;
				let params = {
					fiscalYear: Number(this.sessionStore.fiscalYear),
				};
				const response = await caas.rpc("getSuppliersOfFiscalYear", params);
				if (response.data) {
					this.suppliers = response.data.data
					this.suppliers.sort((a, b) => a.name.localeCompare(b.name));
				} else if (response.data.error) {
					console.log(response.data.error);
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.loading = false;
			}
		},

		refreshPurchaseJournal() {
			this.purchaseJournal = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.getPurchaseJournal();
		},
	},
	mounted() {
		this.getPurchaseJournal();
		this.getSuppliersOfFiscalYear()
	},
};
</script>

<style>
</style>